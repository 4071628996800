<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/map");
require("echarts/lib/chart/lines");
require("echarts/lib/chart/effectScatter");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/geo");
require("echarts/lib/component/toolbox");
require("echarts/lib/component/visualMap");
import { getMapArea } from "@/api/raydata";
import china from "@/common/china.json";

export default {
    data() {
        return {
            regMapName: 'china',
        }
    },
    props: {
        center: Object,
        data: {
            type: Array,
        },
        mapArea: {
            type: String,
            default: ""
        },
    },
    methods: {
        initChart() {
            var points = JSON.parse(JSON.stringify(this.data));
            points.unshift(this.center);
            let colors = ["#FF8C00", "#00acea", "#1DE9B6"];
            var series = [
                {
                    type: "map",
                    map: this.regMapName,
                    aspectScale: 0.75,
                    zoom: 1,
                    roam: false,
                    label: {
                        show: false,
                        textStyle: {
                            color: "#fff",
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    itemStyle: {
                        normal: {
                            areaColor: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#073684", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#061E3D", // 100% 处的颜色
                                    },
                                ],
                            },
                            borderColor: "#215495",
                            borderWidth: 1,
                        },
                        emphasis: {
                            areaColor: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#073684", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#061E3D", // 100% 处的颜色
                                    },
                                ],
                            },
                        },
                    },
                    select: {
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            areaColor: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#073684", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#061E3D", // 100% 处的颜色
                                    },
                                ],
                            },
                        },
                    },
                    data: [
                        {
                            name: "南海诸岛",
                            value: 0,
                            itemStyle: {
                                normal: { opacity: 0, label: { show: false } },
                            },
                        },
                    ],
                },
                {
                    type: "effectScatter", //闪烁点
                    coordinateSystem: "geo",
                    showEffectOn: "render",
                    rippleEffect: {
                        period: 5,
                        scale: 5,
                        brushType: "fill",
                    },
                    hoverAnimation: true,
                    label: {
                        formatter: "{b}",
                        position: "right",
                        offset: [15, 0],
                        color: "#1DE9B6",
                        show: true,
                    },
                    itemStyle: {
                        color: (param) => {
                            if (param.dataIndex == 0) {
                                return "#FF8C00";
                            }
                            return "#00acea";
                        },
                        shadowBlur: 10,
                        shadowColor: "#333",
                        opacity: 0.75,
                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1, //线条宽度
                        },
                    },
                    data: points,
                    zlevel: 1,
                },
            ];

            this.data.forEach((element) => {
                series.push({
                    name: element.name,
                    type: "lines",
                    zlevel: 2,
                    symbol: ["none", "arrow"],
                    symbolSize: 7,
                    effect: {
                        show: true,
                        period: 4,
                        trailLength: 0.02,
                        symbol: "circle",
                        symbolSize: 4,
                        color: "#fff",
                    },
                    lineStyle: {
                        width: 0.5, //线条宽度
                        opacity: 0.5, //尾迹线条透明度
                        curveness: 0.3, //尾迹线条曲直度
                        shadowBlur: 10,
                        color: "#00acea",
                    },
                    emphasis: {
                        lineStyle: {
                            width: 2, //线条宽度
                        },
                    },
                    data: [
                        {
                            name: "",
                            value: 40,
                            coords: [element.value, this.center.value],
                        },
                    ],
                });
            });
            var option = {
                color: colors,
                visualMap: {
                    type: "continuous",
                    seriesIndex: [2, 3],
                    min: 0,
                    max: 100,
                    calculable: true,
                    color: ["#ff3333", "orange", "yellow", "lime", "aqua"],
                    textStyle: {
                        color: "#fff",
                    },
                },
                geo: {
                    map: this.regMapName,
                    show: true,
                    roam: false,
                    label: {
                        emphasis: {
                            show: false,
                        },
                    },
                    layoutSize: "100%",
                    itemStyle: {
                        normal: {
                            borderColor: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#00F6FF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#53D9FF",
                                    },
                                ],
                                false
                            ),
                            borderWidth: 3,
                            shadowColor: "rgba(10,76,139,1)",
                            shadowOffsetY: 0,
                            shadowBlur: 60,
                        },
                    },
                    regions: [
                        {
                            name: "南海诸岛",
                            itemStyle: {
                                // 隐藏地图
                                normal: {
                                    opacity: 0, // 为 0 时不绘制该图形
                                },
                            },
                            label: {
                                show: false, // 隐藏文字
                            },
                        },
                    ],
                },
                series: series,
            };
            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(option);
        },
        getMapArea() {
            var mapArea = this.mapArea;
            if (!mapArea && this.regMapName == 'china') {
                this.regMapName = 'china';
                echarts.registerMap("china", china);
                this.initChart();
            } else {
                getMapArea(mapArea).then((res) => {
                    this.regMapName = mapArea;
                    echarts.registerMap(mapArea, res);
                    this.initChart();
                });
            }
        },
    },
    mounted() {
        this.getMapArea();
    },
    watch: {
        mapArea: function () {
            this.getMapArea();
        },
        data: function () {
            this.getMapArea();
        },
        center: function () {
            this.getMapArea();
        },
    },
};
</script>